<template>
  <div>
    <div v-if="permissions['importacao.index'].permissao">
      <b-button
        v-if="permissions['importacao.descontos.store'].permissao"
        v-b-modal.import-modal
        variant="primary"
      >
        Importar
      </b-button>
      <b-modal
        id="import-modal"
        title="Importação"
        no-close-on-backdrop
        ok-title="Importar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        @ok="enviarImportacao"
      >
        <div id="formulario">
          <label
            for="nome-credor"
            class="h6"
          >
            Credor:
          </label>
          <v-select
            id="nome-credor"
            v-model="credor"
            :options="opcoesCredor"
          />
          <v-select
            v-model="importacao"
            :options="opcoesImportacao"
            dir="auto"
            placeholder="Tipo de Importação"
            class="mt-2 align-center"
          />
          <b-form-file
            v-model="planilha"
            class="mt-2"
            accept=".xls"
            browse-text="Buscar"
            placeholder="Selecione a planilha xls a ser importado"
          />
        </div>
      </b-modal>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BModal,
    BFormFile,
    vSelect,
    BButton,
    notAuthorized,
  },
  data() {
    return {
      permissions: null,
      credor: null,
      importacao: null,
      planilha: null,
      opcoesImportacao: [
        { label: 'Desconto', id: 1 },
        { label: 'Lote de Operações', id: 2 },
      ],
      opcoesCredor: [],
    }
  },
  directive: {
    'b-modal': VBModal,
  },
  created() {
    this.permissions = JSON.parse(localStorage.permissoes).importacao
  },
  mounted() {
    this.$bvModal.show('import-modal')
  },
  async beforeCreate() {
    // GERAR LISTA DE CREDORES PARA SELECIONAR
    await axios.get('api/v1/credores/', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      // eslint-disable-next-line array-callback-return
      res.data.dados.map(credor => {
        this.opcoesCredor.push({
          label: credor.nome,
          id: credor.id,
        })
      })
      return this.opcoesCredor.sort((a, b) => {
        const nameA = a.title.toLowerCase()
        const nameB = b.title.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    })
  },
  methods: {
    async enviarImportacao() {
      const formData = new FormData()
      formData.append('planilha', this.planilha)
      formData.append('tipo', `${this.importacao.id}`)
      formData.append('credor_id', `${this.credor.id}`)
      await axios.post('api/v1/descontos/importar', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          Accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'sucesso',
          text: 'Planilha importada com Sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
          buttonStyling: false,
        })
      }).catch(() => {
        this.$swal({
          title: 'Error!',
          text: 'Erro durante a importação da planilha tente novamente!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
